import React, { useState } from 'react';

const Authenticator = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [inputUsername, setInputUsername] = useState('');
    const [inputPassword, setInputPassword] = useState('');

    const username = 'alik';
    const password = 'narik';

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputUsername === username && inputPassword === password) {
            setAuthenticated(true);
        } else {
            alert('Invalid credentials');
        }
    };

    if (!authenticated) {
        return (
            <div>
                <h1>Login</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={inputUsername}
                        onChange={(e) => setInputUsername(e.target.value)}
                        placeholder="Username"
                        required
                    />
                    <input
                        type="password"
                        value={inputPassword}
                        onChange={(e) => setInputPassword(e.target.value)}
                        placeholder="Password"
                        required
                    />
                    <button type="submit">Login</button>
                </form>
            </div>
        );
    }

    return children;
};

export default Authenticator;
