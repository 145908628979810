import React, { useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { AiFillFilePdf } from 'react-icons/ai';
import axios from 'axios';

const API_BASE_URL = 'https://api.qetax.com'; // Replace with the appropriate base URL of your Flask app

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: 'Roboto', sans-serif;
    transition: all 0.5s linear;
  }
`;

const darkTheme = {
    body: '#2c2c2c',
    text: '#FFF',
};


const lightTheme = {
    body: '#ffffff',
    text: '#282c34',
};

const Label = styled.label`
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
`;

const ChatContainer = styled.div`
  position: absolute;
  bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const ChatBox = styled.div`
  width: 600px;
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.text};
  padding: 1rem;
  border-radius: 5px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ChatInput = styled.input`
  width: 600px;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.text};
  border-radius: 5px;
`;

const Message = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  word-wrap: break-word;

  pre {
    font-family: 'Roboto', sans-serif;
    white-space: pre-wrap;
    word-break: break-word;
    margin: 0;
  }
`;


const SwitchButton = styled.button`
  position: fixed;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.text};
  color: ${({ theme }) => theme.body};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const FileInput = styled.input`
  margin-top: 1rem;
  color: ${({ theme }) => theme.text};
`;

const LoadingDots = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;

  & div {
    position: absolute;
    top: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: ${({ theme }) => theme.text};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  & div:nth-child(1) {
    left: 8px;
    animation: loadingDots 0.6s infinite;
  }

  & div:nth-child(2) {
    left: 8px;
    animation: loadingDots 0.6s infinite 0.2s;
  }

  & div:nth-child(3) {
    left: 32px;
    animation: loadingDots 0.6s infinite 0.4s;
  }

  @keyframes loadingDots {
    0% {
      top: 8px;
      height: 64px;
    }
    50%,
    100% {
      top: 24px;
      height: 12px;
    }
  }
`;

const ClearButton = styled.button`
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.text};
  color: ${({ theme }) => theme.body};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;


const ChatInterface = () => {
    const [darkMode, setDarkMode] = useState(true);
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);


    const addMessage = (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
    };

    const clearChatHistory = () => {
        setMessages([]);
    };

    const sendMessage = async (e) => {
        if (e.key === 'Enter' && inputValue.trim()) {
            addMessage({ type: 'user', text: inputValue });
            setInputValue('');
            setLoading(true);

            const payload = {
                message: inputValue,
                chat_history: messages,
            };

            try {
                const response = await axios.post(`${API_BASE_URL}/message`, JSON.stringify(payload), {
                    headers: { 'Content-Type': 'application/json' },
                });
                setLoading(false);
                addMessage({ type: 'assistant', text: response.data.message });
            } catch (error) {
                setLoading(false);
                console.error('Error sending message:', error);
                addMessage({ type: 'assistant', text: 'Error sending message.' });
            }
        }
    };




    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
            setSelectedFile(file);
            const formData = new FormData();
            formData.append('file', file);
            setLoading(true);

            try {
                const uploadResponse = await axios.post(`${API_BASE_URL}/upload`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                const extractedText = uploadResponse.data.message;
                addMessage({ type: 'assistant', text: extractedText });

                const payload = {
                    message: extractedText,
                    chat_history: messages,
                };

                const response = await axios.post(`${API_BASE_URL}/message`, JSON.stringify(payload), {
                    headers: { 'Content-Type': 'application/json' },
                });
                setLoading(false);
                addMessage({ type: 'assistant', text: response.data.message });
            } catch (error) {
                console.error('Error uploading file:', error);
                setLoading(false);
                addMessage({ type: 'assistant', text: 'Error uploading file.' });
            }
        } else {
            alert('Please select a PDF file.');
        }
    };





    return (
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <GlobalStyle />
            <Container>
                <ChatContainer>
                    <ChatBox>
                        {messages.map((message, index) => (
                            <Message key={index}>
                                <pre>{message.text}</pre>
                            </Message>
                        ))}
                        {loading && (
                            <Message>
                                <LoadingDots>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </LoadingDots>
                            </Message>
                        )}
                    </ChatBox>

                    <InputContainer>
                        <ChatInput
                            type="text"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={sendMessage}
                            placeholder="Type your message and press Enter..."
                        />
                        <Label htmlFor="file-upload">
                            <AiFillFilePdf size="1.5em" color={darkMode ? '#FFF' : '#000'} />
                        </Label>
                        <HiddenFileInput
                            id="file-upload"
                            type="file"
                            accept="application/pdf"
                            onChange={handleFileUpload}
                        />
                    </InputContainer>
                    <ClearButton onClick={clearChatHistory}>Clear</ClearButton>
                </ChatContainer>
                <SwitchButton onClick={() => setDarkMode(!darkMode)}>
                    {darkMode ? 'Light Mode' : 'Dark Mode'}
                </SwitchButton>
            </Container>
        </ThemeProvider>
    );
};

export default ChatInterface;
