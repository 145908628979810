import './App.css';
import ChatInterface from "./ChatInterface";
import Authenticator from "./Authenticator";

function App() {
    return(
      <Authenticator>
        <ChatInterface />
      </Authenticator>
    )
}

export default App;
